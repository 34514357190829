import { template as template_aad9134b9ffc4071a0918762f834cc15 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_aad9134b9ffc4071a0918762f834cc15(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;

import { template as template_919cbb14285c473babbe63ed6a2041c4 } from "@ember/template-compiler";
const WelcomeHeader = template_919cbb14285c473babbe63ed6a2041c4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

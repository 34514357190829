import { template as template_c32b7f8338b74e278b9d054d3f14b27c } from "@ember/template-compiler";
const FKControlMenuContainer = template_c32b7f8338b74e278b9d054d3f14b27c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_882e6e9f48fd4fa1818a3d963e3554a2 } from "@ember/template-compiler";
const FKText = template_882e6e9f48fd4fa1818a3d963e3554a2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
